<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CRow>
                <CCol sm="12">
                  <CIcon name="cil-grid"/>
                  {{ $t('checkout.Checkout') }}
                </CCol>
              </CRow>
            </slot>
          </CCardHeader>
          <CCardBody>
            <CDataTable :hover="false"
                        :striped="false"
                        :bordered="false"
                        :fixed="false"
                        :dark="false"
                        :pagination="false"
                        :fields="checkoutListFields"
                        :items="$store.state.transaction.checkout.subscriptions">
              <template #plan="{item}">
                <td>
                  {{ item.subscription.plan.name }}
                </td>
              </template>
              <template #cname="{item}">
                <td>
                  {{ item.subscription.cname }}
                </td>
              </template>
            </CDataTable>
            <div style="text-align: right">
              <h3><b>{{ $t('checkout.TotalPrice') }}</b> : {{ $store.state.transaction.checkout.amount_currency1 }}</h3>
              <CButton color="secondary"
                       class="mr-2"
                       @click="$router.push({name: 'MemberPlans'})">
                {{ $t('checkout.AddItem') }}
              </CButton>
              <CButton color="primary"
                       @click="showModal=true">
                {{ $t('checkout.Pay') }}
              </CButton>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CModal :title="$t('checkout.SelectPaymentMethod')"
            :show.sync="showModal">
      <input type="radio"
             id="cryptocurrency"
             name="payment_type"
             value="cryptocurrency"
             v-model="selectedPaymentType">
      <label for="cryptocurrency">{{ $t('checkout.Cryptocurrency') }}</label>
      <ul id="coins" :class="{Disabled: selectedPaymentType !== 'cryptocurrency'}">
        <li v-for="coin in coinSupports"
            :key="'coin-' + coin.id"
            @click="clickCoins(coin.code, $event)">
          <div class="box"></div>
          <img :src="coin.icon"/>
          <p>{{ coin.name }}</p>
        </li>
      </ul>
      <input type="radio"
             id="accountbalance"
             name="payment_type"
             value="account_balance"
             v-model="selectedPaymentType">
      <label for="accountbalance">{{ $t('checkout.AccountBalance') }}</label>
      <p>{{ $t('checkout.YourBalance') }}: <b :class="{BalanceDanger: parseFloat($store.state.balance) < parseFloat($store.state.transaction.checkout.amount_currency1)}">{{ $store.state.balance }}</b></p>
      <template v-slot:footer>
        <button
            type="button"
            class="btn btn-secondary"
            @click="showModal=false">
          {{ $t('Cancel') }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          @click="clickPay()">
          {{ $t('Pay') }}
        </button>
      </template>
    </CModal>
  </div>
</template>

<script>
import axios from '@/plugins/axios'
import { payPlan } from '@/utilities/api'

export default {
  name: 'Checkout',
  data() {
    return {
      showModal: false,
      selectedPaymentType: null,
      coinSupports: []
    }
  },
  computed: {
    checkoutListFields() {
      return [
        {
          key: 'plan',
          label: this.$t('checkout.PlanName')
        },
        {
          key: 'cname',
          label: this.$t('checkout.CNAME')
        },
        {
          key: 'additional_domain_number',
          label: this.$t('checkout.AdditionalDomainNumber')
        },
        // {
          // key: 'additional_traffic',
          // label: this.$t('checkout.AdditionalTraffic')
        // },
        {
          key: 'amount_price',
          label: this.$t('checkout.AmountPrice')
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('fetchCheckout')
    this.fetchCoinSupport()
  },
  methods: {
    fetchCoinSupport() {
      axios.get('plan/coin-support/').then(response => {
        this.coinSupports = response.data
      }).catch(error => {
        console.log(error)
      })
    },
    clickCoins(coin, $event) {
      if (this.selectedPaymentType === 'cryptocurrency') {
        const li = document.querySelectorAll('ul#coins li')
        li.forEach(item => {
          item.classList.remove('Active')
        })
        const $target = $event.target
        $target.parentNode.classList.toggle('Active')
        this.selectedCoin = coin
      }
    },
    clickPay() {
      if (this.selectedPaymentType) {
        if (this.selectedPaymentType === 'cryptocurrency') {
          if (!this.selectedCoin) {
            this.flash(this.$t('checkout.WarningCoin'), 'warning')
            return
          }
        }
        if (this.selectedPaymentType === 'account_balance') {
          if (parseFloat(this.$store.state.balance) < parseFloat(this.$store.state.transaction.checkout.amount_currency1)) {
            this.flash(this.$t('checkout.WarningBalance'), 'warning')
            return
          }
          this.selectedCoin = 'USDT'
        }

        payPlan(this.$store.state.transaction.checkout.bill_no, this.selectedCoin, this.selectedPaymentType).then(response => {
          this.showModal = false
          this.flash(this.$t('checkout.SuccessPaymentType'), 'success', {timeout: 4000})
          this.$store.dispatch('fetchCheckout')
          if (this.selectedPaymentType === 'cryptocurrency') {
            window.open(response.data.checkout_url, 'Checkout').focus()
          }
          if (this.selectedPaymentType === 'account_balance') {
            axios.get('plan/balance/').then(response => {
              this.$store.state.balance = response.data.amount
            }).catch(error => console.log(error))
          }
          this.$router.push({name: 'DomainList'})
        }).catch(error => console.log(error))
      } else {
        this.flash(this.$t('checkout.WarningPaymentType'), 'warning')
      }
    }
  }
}
</script>

<style scoped>
ul#coins {
  padding: 0;
  list-style: none;
}

ul#coins.Disabled li {
  border: unset !important;
  background-color: #eee;
}

ul#coins li {
  position: relative;
  display: inline-block;
  padding: 10px;
  margin: 10px;
  border: 1px solid #eee;
  border-radius: 10px;
  cursor: pointer;
}

ul#coins li.Active {
  border: 1px solid #0064bd;
}

ul#coins li div.box {
  position: absolute;
  top: 0;
  width: 100px;
  height: 100px;
}

ul#coins li img {
  width: 100px;
  height: 100px;
}

ul#coins p {
  margin: 0;
  text-align: center;
}
.BalanceDanger {
  color: #e55353;
}
</style>
